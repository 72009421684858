import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue')
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('@/views/start/Start.vue')
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: () => import('@/views/FAQs/FAQs.vue')
  },
  {
    path: '/About',
    name: 'About',
    component: () => import('@/views/about/About.vue')
  },
  {
    path: '/Locations',
    name: 'Locations',
    component: () => import('@/views/locations/Locations.vue')
  },
  {
    path: '/kong',
    name: 'kong',
    component: () => import('@/views/kong/kong.vue')
  }
]

const router = new VueRouter({
  routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 路由跳转后回到顶部
router.afterEach(() => {
  store.state.side = false
  window.scrollTo(0, 0)
})
export default router