<template>
  <div id="app" :class="side ? 'side' : ''">
    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["side"]),
  },
  methods: {
    // pageMove(event){
    //   if(this.side){
    //     event.preventDefault()
    //   }
    // }
  },
};
</script>
<style lang="less">
@font-face {
  font-family: Poppins-Black; //定义font的名字
  src: url("~@/assets/fonts/Poppins-Black.otf"); //把下载的字体文件引入进来
}
@font-face {
  font-family: Poppins-Regular; //定义font的名字
  src: url("~@/assets/fonts/Poppins-Regular.otf"); //把下载的字体文件引入进来
}
@font-face {
  font-family: Poppins-ExtraBold; //定义font的名字
  src: url("~@/assets/fonts/Poppins-ExtraBold.otf"); //把下载的字体文件引入进来
}
@font-face {
  font-family: Poppins-SemiBold; //定义font的名字
  src: url("~@/assets/fonts/Poppins-SemiBold.otf"); //把下载的字体文件引入进来
}
@font-face {
  font-family: Poppins-Medium; //定义font的名字
  src: url("~@/assets/fonts/Poppins-Medium.otf"); //把下载的字体文件引入进来
}
body {
  background: #f5c03e;
}
#app {
  transition: all 0.5s;
}
.side {
  transform: translate(-80%, 100px);
}
</style>
